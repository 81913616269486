@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

.container-fluid {
  @apply mx-auto px-2 sm:px-4 lg:px-6;
  max-width: 96em;
}

.btn {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.report-table {
  @apply min-w-full divide-y divide-gray-200;
}


.report-table thead {
  @apply bg-gray-50;
}

.report-table thead th {
  @apply px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center;
  line-height: 1.3;
}

.report-table thead .currency {
  font-size: 10px;
  color: #999;
  font-weight: normal;
}

.report-table tbody {
  @apply divide-y divide-gray-200;
}

.report-table tbody tr:nth-child(even) {
  @apply bg-gray-50;
}


.report-table tfoot tr {
  @apply bg-gray-100 font-bold;
}



.report-table tbody td, 
.report-table tfoot td  {
  @apply px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-center;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
